import { RiMailSendFill, RiPhoneFill, RiLinksLine } from "react-icons/ri";
import { IoLogoTableau } from "react-icons/io5";
const InformationsData = [
    {
        key: "1",
        Icon: <RiMailSendFill size={20} />,
        Name: "Email",
        Value: "geraldonatanael84@gmail.com",
        Link: "mailto:geraldonatanael84@gmail.com",
    },
    {
        key: "2",
        Icon: <RiPhoneFill size={20} />,
        Name: "Phone",
        Value: "+62 87781857116",
        Link: "tel:+6287781857116",
    },
    {
        key: "3",
        Icon: <RiLinksLine size={20} />,
        Name: "Website",
        Value: "natanaelgeraldo.com",
        Link: "https://natanaelgeraldo.com",
    },
    {
        key: "4",
        Icon: <IoLogoTableau size={20} />,
        Name: "Tableau",
        Value: "Tablue Profile",
        Link: "https://public.tableau.com/app/profile/natanael.geraldo.sulaiman/vizzes",
    },
];
export default InformationsData;
