import movieDay from "../Assets/Portofolio/MovieDay.webp";
// import HIMTIElection from "../Assets/Portofolio/HimtiElection.webp";
// import HIMTIMainWebsite from "../Assets/Portofolio/HimtiMainWebsite.webp";
import popUp from "../Assets/Portofolio/Pop-Up.webp";
import portfolioImage from "../Assets/Portofolio/Portfolio.webp";
import schoolScore from "../Assets/Portofolio/SchoolScore.webp";
import webVR from "../Assets/Portofolio/WebVR.webp";
import webHishot from "../Assets/Portofolio/hishot.webp";
import azureDevOps from "../Assets/Portofolio/AzureDevOps.png";
import rottenTomato from '../Assets/Portofolio/Rotten Tomato.png'
import collisionAirport from '../Assets/Portofolio/Collision Airport.png'

const PortofoliosData = [
    {
        key: 33,
        title: "Rotten Tomato Analysis",
        description:
            "The Rotten Tomato Analysis dashboard, created by Natanael Geraldo Sulaiman, provides an interactive visualization of movie ratings and reviews from Rotten Tomatoes, comparing the Tomatometer (critic reviews) with the Audience Score (public ratings). It includes filters to explore ratings by genre, year, or score, offering detailed insights into film performance and audience reception.",
        technology: ["Data Analysis", "Tableau", "CSV"],
        type: "Data Analyst",
        status: 1,
        url: "https://public.tableau.com/app/profile/natanael.geraldo.sulaiman/viz/RottenTomato_17327837184970/Dashboard1",
        git: null,
        image: rottenTomato,
    },
    {
        key: 34,
        title: "Collision Airport",
        description:
            "The Collision Airport dashboard, created by Natanael Geraldo Sulaiman, provides an interactive visualization of airport collision data, offering insights into incident patterns and contributing factors.",
        technology: ["Data Analysis", "Tableau", "CSV"],
        type: "Data Analyst",
        status: 1,
        url: "https://public.tableau.com/app/profile/natanael.geraldo.sulaiman/viz/RottenTomato_17327837184970/Dashboard1",
        git: null,
        image: collisionAirport,
    },
    {
        key: 1,
        title: "Movie Review App",
        description:
            "This is a React Native App Using API to display all the category of movie this their description and some overview about the movie.",
        technology: ["React Native", "Figma", "API"],
        type: "Mobile Development",
        status: 1,
        url: null,
        git: "https://github.com/NatanaelGeraldoS/MovieDay",
        image: movieDay,
    },
    {
        key: 31,
        title: "Azure DevOps Monitoring",
        description:
            "The Azure DevOps Monitoring project is a web application designed to display real-time Continuous Integration (CI) and Continuous Deployment (CD) activities based on Azure environments. It also features active pull request tracking, task assignments for team members, and a secure login system to ensure access is restricted to authorized users only.",
        technology: ["React.JS", "REST API"],
        type: "Web Development",
        status: 1,
        url: "https://itdivdevops.natanaelgeraldo.com/",
        git: null,
        image: azureDevOps,
    },
    {
        key: 29,
        title: "Pokemon React Native",
        description:
            "Pokemon React Native is a mobile application designed to provide a comprehensive list of Pokémon along with detailed information for each species. Developed using React Native, this app offers users an intuitive interface to explore and access extensive details about various Pokémon, including their abilities, stats, evolutions, and other relevant information, creating an immersive and informative experience for fans and enthusiasts alike.",
        technology: ["React Native", "API"],
        status: 1,
        type: "Mobile Development",
        url: null,
        git: "https://github.com/NatanaelGeraldoS/Pokemon-ReactNative",
    },
    {
        key: 30,
        title: "RegiApp",
        description:
            "RegiApp is a robust Fullstack Web application built with the MERN stack, offering comprehensive functionalities such as user authentication, event management, and simplified event booking processes. This versatile platform provides users with a seamless experience, allowing them to securely authenticate, manage events efficiently, and easily book their desired events through a user-friendly interface, streamlining the event registration process.",
        technology: ["React.JS", "Express.JS", "MongoDB", "Node.JS"],
        status: 1,
        type: "Web Development",
        url: null,
        git: "https://github.com/NatanaelGeraldoS/RegiApp-MERN",
    },
    {
        key: 6,
        title: "HISHOT 2021",
        description:
            "Create a landing page for the HISHOT 2021 event held by HIMTI as a publication medium for the event",
        technology: ["HTML", "CSS", "Javascript"],
        status: 1,
        type: "Web Development",
        url: "https://hishot.natanaelgeraldo.com/",
        git: "https://github.com/NatanaelGeraldoS/HISHOT2021",
        image: webHishot,
    },
    {
        key: 7,
        title: "HISHOT Website VR",
        description:
            "Create a VR website to display the history of VR to provide interaction and entertainment from the HISHOT 2021 website",
        technology: ["HTML", "CSS", "Javascript", "A-FRAME"],
        status: 1,
        type: "VR Web Development",
        url: "https://hishot.natanaelgeraldo.com/VRWebsite/index.html",
        git: "https://github.com/NatanaelGeraldoS/HISHOT2021",
        image: webVR,
    },
    {
        key: 17,
        title: "Face Recognition",
        description:
            "This Python-based Face Recognition project explores and compares three distinct methods of facial recognition using a dataset comprising over 2000 images, aiming to analyze their accuracy, efficiency, and applicability in real-world scenarios.",
        technology: ["Python", "JSON", "MySQL"],
        status: 1,
        type: "Artificial Intelligence",
        url: null,
        git: "https://github.com/NatanaelGeraldoS/Face-Recognition-Comparisons",
    },
    {
        key: 18,
        title: "SmartPot",
        description:
            "SmartPot is an innovative IoT project leveraging Arduino technology to monitor and track the humidity levels and water status of potted plants. Integrated seamlessly with a website using ASP.NET C#, this system offers real-time insights, enabling users to remotely manage plant care by providing accurate data on moisture levels and water requirements for optimal plant health.",
        technology: ["C#", "Arduino", "ASP.NET"],
        status: 1,
        type: "IoT & Web Development",
        url: null,
        git: "https://github.com/NatanaelGeraldoS/SmartPot",
    },
    {
        key: 19,
        title: "Twitter Emotion Detector",
        description:
            "The Twitter Emotion Detector is an advanced NLP-driven system designed to analyze and detect emotions expressed within tweets. Leveraging Natural Language Processing techniques, this tool accurately identifies and categorizes the emotional tone of tweets, providing valuable insights into the sentiments and feelings conveyed across the platform's diverse range of user-generated content.",
        technology: ["Python", "API"],
        status: 1,
        type: "NLP",
        url: null,
        git: null,
    },
    {
        key: 20,
        title: "Pokemon Website Graphql",
        description:
            "The Pokémon Website GraphQL project is a dynamic web application built with React.js and GraphQL, designed to showcase an extensive list of Pokémon retrieved from a provided API. This interactive platform offers users an engaging experience, allowing them to explore and access detailed information about various Pokémon species through seamless integration with GraphQL, enhancing both performance and flexibility in data retrieval.",
        technology: ["Graphql", "React.JS"],
        status: 1,
        type: "Web Development",
        url: null,
        git: "https://github.com/NatanaelGeraldoS/pokemongraphql",
    },
    {
        key: 21,
        title: "HIMTI CheckOut",
        description:
            "HIMTI CheckOut is a specialized website developed for the HIMTI organization, offering a streamlined checkout solution after events to alleviate crowded conditions. This platform enables attendees to efficiently complete their post-event processes, reducing congestion by providing an online checkout system tailored to the organization's needs, ensuring a smoother and more organized event conclusion.",
        technology: ["PHP", "HTML", "CSS"],
        status: 1,
        type: "Web Development",
        url: null,
        git: "https://github.com/NatanaelGeraldoS/HIMTI-CheckOut",
    },
    {
        key: 22,
        title: "Markdown Editor",
        description:
            "The Markdown Editor project is a web-based application crafted with React.js, aimed at assisting users in converting Markdown syntax into a visually displayed text. This user-friendly website empowers individuals to effortlessly compose and view formatted content, enabling seamless conversion of Markdown language into a clear, readable format in real-time, enhancing the editing and visualization experience.",
        technology: ["React.JS"],
        status: 1,
        type: "Web Development",
        url: "https://markdowneditorng.netlify.app/",
        git: "https://github.com/NatanaelGeraldoS/Markdown-Editor",
    },
    {
        key: 23,
        title: "Portofolio V2",
        description:
            "Portfolio V2 is the upgraded version of my personal website showcasing enhanced animations and layout designs. This iteration focuses on elevating user experience through captivating visual elements and improved layouting techniques, presenting my portfolio in a dynamic and engaging manner, reflecting my skills and achievements more vividly.",
        technology: ["HTML", "CSS"],
        status: 1,
        type: "Web Development",
        url: null,
        git: "https://github.com/NatanaelGeraldoS/PortofolioWeb",
        image: portfolioImage,
    },
    {
        key: 24,
        title: "Maiboutique",
        description:
            "Maiboutique is a collaborative project developed as part of my university's web programming laboratory course. Created by teams of two, this project utilizes Laravel to construct a fully functional CRUD website aimed at facilitating clothing purchases. The platform enables users to browse, buy, and manage clothing items, demonstrating proficiency in web development and database management as part of the coursework.",
        technology: ["Laravel"],
        status: 1,
        type: "Web Development",
        url: null,
        git: null,
    },
    {
        key: 25,
        title: "Doorprize Checker",
        description:
            "The Doorprize Checker project is a specialized tool designed for streamlined verification of door prize numbers and attendee validation at events organized by my organization. This solution simplifies the process of confirming the validity of door prize numbers and attendees' presence, ensuring smooth and efficient management during events while enhancing overall accuracy and accountability.",
        technology: ["Laravel"],
        status: 1,
        type: "Web Development",
        url: null,
        git: null,
    },
    {
        key: 32,
        title: "HIMTI Re-Registration",
        description:
            "HIMTI Re-Registration is a dynamic website built on the Laravel framework, serving as a comprehensive platform to facilitate the registration and record-keeping of organizational activities. This user-friendly website offers a dynamic form system, empowering members to easily submit and record their participation in various HIMTI (Himpunan Mahasiswa Teknik Informatika) organization activities, ensuring efficient organization management and documentation.",
        technology: ["Laravel"],
        status: 1,
        type: "Web Development",
        url: null,
        git: null,
    },
    {
        key: 26,
        title: "HIMTI VR",
        description:
            "HIMTI VR is an innovative project aimed at providing entertainment and promoting our computer science organization through Virtual Reality (VR) technology. This immersive VR experience introduces users to HIMTI, offering information about the organization and its location in an engaging, interactive environment. By leveraging VR technology, this project aims to captivate audiences while effectively showcasing HIMTI's objectives and presence within the computer science community.",
        technology: ["A-Frame", "HTML", "Javascript", "CSS"],
        status: 1,
        type: "Web Development",
        url: null,
        git: null,
    },
    {
        key: 8,
        title: "HIMTI Election 2021",
        description:
            "The HIMTI Election 2021 website serves as a dedicated platform facilitating the election process for both lecturers and students to cast their votes for the HIMTI Chairman position. This interactive website ensures a transparent and accessible voting system, enabling lecturers and students to participate in the democratic process seamlessly, promoting fair and accountable elections within the HIMTI organization.",
        technology: ["HTML", "CSS", "Javascript", "Laravel", "Bootstrap"],
        status: 1,
        type: "Web Development",
        url: null,
        git: null,
        image: "https://drive.google.com/uc?export=view&id=1wH2elEvxPSr8u99RDOPR1h3mquZ1yZYN",
    },
    {
        key: 9,
        title: "HIMTI Main Page",
        description:
            "HIMTI Main Page serves as the central hub offering comprehensive and general information about the HIMTI (Himpunan Mahasiswa Teknik Informatika) organization. This web page functions as an introduction to HIMTI, providing an overview of its objectives, history, events, achievements, and opportunities available within the organization. It aims to inform visitors, prospective members, and stakeholders about HIMTI's mission, values, and contributions in the field of information technology.",
        technology: ["HTML", "CSS", "Javascript", "PHP"],
        status: 1,
        type: "Web Development",
        url: null,
        git: null,
        image: "https://drive.google.com/uc?export=view&id=1C5uLMVSNEb7p30CxtXWWM1odDAH9I8YL",
    },
    {
        key: 2,
        title: "Teacher Comment",
        description:
            "Create a module for teachers to comment on the achievements obtained by students at the BINUS SCHOOL Serpong Staff Desk and make the output to the staff, parents, and students of the BINUS SCHOOL Serpong Desk",
        technology: ["ASP.NET", "SQL Server"],
        status: 1,
        type: "Web Development",
        url: null,
        git: null,
    },
    {
        key: 3,
        title: "Blended Learning Programee Module",
        description:
            "Create a module used for Parents to fill in the health status of their children during pandemic conditions, and will issue results on whether students can enter school or not. As well as creating a QR Scanner module as a tool to validate student status",
        technology: ["ASP.NET", "SQL Server"],
        status: 1,
        type: "Web Development",
        url: null,
        git: null,
    },
    {
        key: 4,
        title: "Merit and Demerit Module",
        description:
            "Added a filter feature for these modules to make it easier for teachers to input achievements or violations committed by students and added a display for SCP teachers for input merit and demerit students",
        technology: ["ASP.NET", "SQL Server"],
        status: 1,
        type: "Web Development",
        url: null,
        git: null,
    },
    {
        key: 5,
        title: "Covid-19 Website",
        description:
            "The Covid-19 Website is a comprehensive platform designed to offer vital information regarding the coronavirus pandemic. It provides a detailed list of countries along with updated statistics on confirmed Covid-19 cases for each nation. This user-friendly website serves as a reliable source, offering real-time data on the global impact of the virus, aiding individuals, researchers, and policymakers in tracking and understanding the spread and severity of Covid-19 worldwide.",
        technology: ["React JS", "API"],
        status: 1,
        type: "Web Development",
        url: null,
        git: null,
    },
    {
        key: 10,
        title: "Portofolio Website V1",
        description:
            "Portfolio Website V1 is a personalized digital platform crafted to promote and exhibit my professional identity, skills, and personal information. Serving as an online showcase, this website highlights my career journey, educational background, accomplishments, skills, and projects. Its primary aim is to introduce myself to potential employers, clients, or collaborators by presenting a comprehensive overview of my expertise, achievements, and personality in a visually appealing and informative manner.",
        technology: ["HTML", "CSS", "Javascript"],
        status: 1,
        type: "Web Development",
        url: null,
        git: "https://github.com/NatanaelGeraldoS/portofolio",
    },
    {
        key: 11,
        title: "FAME",
        description:
            "The FAME Selection Project for HIMTI involves my role as a Web Developer utilizing HTML, CSS, JavaScript, and Bootstrap to revamp the organization's online platform. I'm tasked with crafting a visually engaging and responsive interface, incorporating interactive elements and ensuring optimal performance to elevate the user experience.",
        technology: ["HTML", "CSS", "Javascript", "Bootstrap"],
        status: 1,
        type: "Web Development",
        url: "https://natanaelgeraldos.github.io/FAMEHIMTIIntern/",
        git: "https://github.com/NatanaelGeraldoS/FAMEHIMTIIntern",
    },
    {
        key: 12,
        title: "Lentera Harapan Curug Website",
        description:
            "Create a website that is used as an assignment, to display information about the school and there is a login feature and add a schedule to the website",
        technology: ["PHP", "HTML", "CSS", "Javascript"],
        status: 1,
        type: "Web Development",
        url: null,
        git: null,
    },
    {
        key: 13,
        title: "Student Score Management",
        description:
            "Create a website module to input the value of each student owned, and register new students and add complete information about the student",
        technology: ["ASP.NET", "SQL Server"],
        status: 1,
        type: "Web Development",
        url: null,
        git: null,
        image: schoolScore,
    },
    {
        key: 14,
        title: "Discord Bot Reminder",
        description:
            "Creating a discord bot that is useful for creating reminders for tasks and deadlines that exist on the day, as well as additional features for either adding or canceling schedules",
        technology: ["Python", "JSON"],
        status: 1,
        type: "Bot Development",
        url: null,
        git: null,
    },
    {
        key: 15,
        title: "Pop-Up Alert Animation",
        description:
            "Create custom pop-ups for alerts and success pop ups added with animations to increase user experince",
        technology: ["HTML", "CSS", "Javascript"],
        status: 1,
        type: "Web Development",
        url: "https://popup.natanaelgeraldo.repl.co/",
        git: "https://github.com/NatanaelGeraldoS/Pop-Up",
        image: popUp,
    },
    {
        key: 16,
        title: "Python Bot Update Data",
        description:
            "Create a discord bot that is used to display and transform participant data from the TECHNO 2022 event",
        technology: ["Python", "JSON", "MySQL"],
        status: 1,
        type: "Bot Development",
        url: null,
        git: null,
    },
    {
        key: 27,
        title: "Flappy Bird",
        description:
            "Flappy Bird is a desktop application created in C, featuring a game reminiscent of Flappy Bird. Players navigate a bird through obstacles, aiming to maneuver it successfully and avoid collision to advance through the game. The challenge lies in guiding the bird safely through the obstacles, providing an entertaining and engaging gaming experience.",
        technology: ["C"],
        status: 1,
        type: "Console App",
        url: null,
        git: "https://github.com/NatanaelGeraldoS/FlappyBird-C",
    },
    {
        key: 28,
        title: "Snake Game",
        description:
            "Snake Game is a desktop application developed in C, offering an interactive gaming experience akin to the classic Snake game. Players control a snake that grows in length as it consumes food while navigating through a confined space. The objective is to avoid self-collisions and navigate the snake to collect food, progressively increasing the challenge and excitement as the snake grows longer.",
        technology: ["C"],
        status: 1,
        type: "Console App",
        url: null,
        git: "https://github.com/NatanaelGeraldoS/SnakeGame-C",
    },
];

export default PortofoliosData;
